<script>


/**
 * Auto Complete Select component
 */

import Choices from "choices.js";

import { autoComplete } from '@/api/misc'

export default {

    emits: ['selected'],

    props: ['types', 'search_prompt'],

    components: {

    },

    validations: {

    },



    data() {
        return {
            items: [],
            item_choices: {}
        }
    },

    methods: {

        onSearched(e) {
            autoComplete({ "str": e.detail.value, types: this.types }).then((res) => {
                this.items = []
                this.items.push({
                    label: 'All',
                    value: ''
                })
                res.data.map((d) => {
                    this.items.push({
                        label: d.str_id,
                        value: d.id,
                        agent: d.s_type == 'AGENT' ? d : null,
                        property: d.s_type == 'PM' ? d : null,
                        deal: d.s_type == 'DEAL' ? d : null,
                        listing: d.s_type == 'LISTING' ? d : null
                    })
                })
                this.item_choices.clearChoices()
                this.item_choices.setChoices(this.items)
                this.item_choices.showDropdown()
            })

        },

        onSelected(ev) {
            let e = this.items.find((e) => e.value == ev.detail.value)
            this.$emit('selected', e)
        }
    },

    created() {

    },

    mounted() {

        this.item_choices = new Choices('#choices-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: this.search_prompt,
        })
    }

};
</script>

<template>
   
        <select class="form-control" placeholder="PM/Agent" name="choices-input" @search="onSearched" @change="onSelected"
            id="choices-input">
            <option value="">All</option>
        </select>
    
</template>