<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'

import AutoCompleteSelect from '../components/AutoCompleteSelect.vue'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";


/**
 * User list component
 */
export default {
  page: {
    title: "Trust Account Mgt",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Trust Account Mgt",
      items: [
        {
          text: "Account Payable",
          href: "/",
        },
        {
          text: "Trust Account Mgt",
          href: "/",
          active : true
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "G",
          sortable: true,
        },

        {
          key: "ID",
          sortable: true,
        },


        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Payee",
          sortable: true,
        },
        {
          key: "PayMethod",
          sortable: true,
        },

        {
          key: "PaymentDate",
          sortable: true,
        },
        {
          key: "Amount",
          sortable: false,
        },
        {
          key: "ProcessDate",
          sortable: true,
        },

        {
          key: "Reference",
          sortable: true,
        },

        {
          key: "Status",
          sortable: false,
        },

        {
          key: "Action",
          sortable: true,
        },
      ],
      rangeOptions: {
        mode: "range",
      },
     

      query_agent_id: '',
      query_deal_id: '',
      queryRange : '',
      query_status : ['Pending']
    };
  },
  components: {
    Layout,
    PageHeader,
    AutoCompleteSelect,
    flatPickr
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {

    new Choices('#status_choice', {
      placeholderValue: "This is a placeholder set in the config",
      searchPlaceholderValue: "This is a search placeholder",
      removeItemButton: true,
    });

  },
  methods: {

    queryList() {

      let data = {
        action: "AP_TRUST",
        page: {
          page: this.currentPage,
          page_size: this.perPage
        },
        filter: {}
      }


     if (this.query_status && this.query_status.length > 0) {
        data.filter.status = this.query_status
     }


      if (this.queryRange) {
        let da = this.queryRange.replaceAll('to' ,'')
        let dateArr = da.split(' ')
        if (dateArr.length > 2) {
          data.filter.start_date = dateArr[0]
          data.filter.end_date = dateArr[2]
        } else {
          data.filter.start_date = dateArr[0]
        }
      }
      

      if (this.query_agent_id) {
        data.filter.agent_id = this.query_agent_id
      }

      if (this.query_deal_id) {
        data.filter.deal_id = this.query_deal_id
      }

      getCashApi().list(data).then((res) => {
        this.list = []
        res.data.map((o) => {

          this.list.push({
            id: o.id,
            type: o.type,
            due_date: o.due_date,
            amount: o.amount,
            remarks: o.remarks,
            ref_id: o.ref_id,
            ref_type: o.ref_type,
            status: o.status,
            pay_date: o.pay_date,
            payee: o.payer,
            pay_method: o.pay_method,
            order_deal_id: o.order_deal_id,

          })
          this.totalRows = res.page.total;

        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    approve(ap) {
      console.log(ap)

    },

    pay(ap) {
      getCashApi().pay({ ap_type: 'AP_TRUST', ap_id: ap.id, cheque_id: '312540' }).then((res) => {
        if (res.errCode == 0) {
          ap.status = 'Paied'
          this.$alertify.message("Paied ");
        } else {
          this.$alertify.message("Failed to Approve " + res.msg);
        }

      })
    },

    onSearch(item) {
      this.currentPage = 1;
      if (item.agent) {
        this.query_agent_id = item.agent.id
      } else {
        this.query_agent_id = ''
      }

      if (item.deal) {
        this.query_deal_id = item.deal.id
      } else {
        this.query_deal_id = ''
      }

      this.queryList()
    }
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    AP Trust
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->


            </div>
            <!-- end row -->

            <div class="row">

              <div class="col-md-2 mb-3">
                <AutoCompleteSelect :types="['DEAL']" :search_prompt="'Search By DEAL ID'" @selected="onSearch" />
              </div>

              <div class="col-md-2 mb-3">
                <flat-pickr v-model="queryRange" @on-change="queryList()" :config="rangeOptions" placeholder="Payment Date"
                  class="form-control" ></flat-pickr>
              </div>
              <div class="mb-2 col-md-3 ">
                <select name="choices-multiple-remove-button" id="status_choice" multiple v-model="query_status" @change="queryList()">
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Complete">Complete</option>
                  <option value="Cancelled">Cancelled</option>
                </select>

              </div>

              <div class="col-md-5 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">

                <b-button variant="info">Process</b-button>

              </div>

              <!-- end col -->

            </div>

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                  class="table-check">

                  <template #cell(G)>
                    <input type="checkbox" />
                  </template>
                  <template #cell(ID)="data">
                    <router-link :to="{ name: 'deal-overview', query: { deal_id: `${data.item.order_deal_id}` } }"
                      class="text-body fw-medium">{{ data.item.id }}</router-link>
                  </template>

                  <template #cell(Reference)="data">
                    <router-link :to="{ name: 'deal-overview', query: { deal_id: `${data.item.order_deal_id}` } }"
                      class="text-body fw-medium">{{ data.item.order_deal_id }}</router-link>
                  </template>

                  <template #cell(Type)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(Payee)="data">
                    {{ data.item.payee }}
                  </template>

                  <template #cell(ProcessDate)="data">
                    {{ data.item.due_date }}
                  </template>
                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PaymentDate)="data">
                    {{ data.item.pay_date }}
                  </template>
                  <template #cell(PayMethod)="data">
                    {{ data.item.pay_method }}
                  </template>

                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>
                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="approve(data.item)"
                        :disabled="!(data.item.status == 'Pending')">Approve</b-dropdown-item>
                      <b-dropdown-item @click="pay(data.item)" :disabled="!(data.item.status == 'Approved')">Print
                        Cheque</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
